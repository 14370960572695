import 'primeflex/primeflex.css';
import "primereact/resources/themes/saga-orange/theme.css"; 
import "primereact/resources/primereact.min.css";                 
import 'primeicons/primeicons.css';
import React, { useState, useEffect } from 'react';
import '../../Font.css';
import { Button } from 'primereact/button';
import { Galleria } from 'primereact/galleria';
import PhotoService from './PhotoService.js';
import { useTranslation } from 'react-i18next';


function About(){

        
    const [language, setLanguage] = useState('FR');
    const { t, i18n } = useTranslation();

    const changeLanguage = (lang) => {
        setLanguage(lang);
        i18n.changeLanguage(lang);
    }


    const [images, setImages] = useState(null);
    
    const responsiveOptions = [
        {
            breakpoint: '3440px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 2
        }
    ];

    useEffect(() => {
        PhotoService.getImages(t).then(data => setImages(data));
    }, []); 

    const itemTemplate = (item) => {
        return <img src={item.itemImageSrc} alt={t(item.alt)} title={t(item.title)} style={{ width: '100%', display: 'block' }} />
    }

    const thumbnailTemplate = (item) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block' }} />
    }
    const caption = (item) => {
        return (
            <React.Fragment>
                <div className="text-xl mb-2 font-bold">{t(item.title)}</div>
                <p className="text-white">{t(item.alt)}</p>
            </React.Fragment>
        );
    }

    return (
        <div class="grid">

            <div class="col max-w-full">
                <Galleria value={images} responsiveOptions={responsiveOptions} numVisible={7} circular autoPlay transitionInterval={3000} style={{ maxWidth: '600px'} }
                item={itemTemplate} thumbnail={thumbnailTemplate} showItemNavigators className="m-auto" caption={caption}/>
            </div>
            <div class="col font">
                <h1 className="text-center">{t('')}</h1>
                <h3>{t('AboutBack')}</h3>
                <p>{t('NameText')} : Justin Alves</p>
                <h4>{t('Biography')}</h4>
                <p>{t('BioText')}</p>
                <p><b>{t('BornText')}: </b>{t('BornDate')}</p>
                <p><b>{t('CurrentEmploymentText')}: </b> {t('CurrentEmployment')}</p>
                <h3>{t('Hobby')}</h3>
                <ul>
                    <li>{t('Hobby1')}</li>
                    <li>{t('Hobby2')}</li>
                    <li>{t('Hobby3')}</li>
                </ul>
                <h3>{t('Interest')}</h3>
                <ul>
                    <li>{t('Interest1')}</li>
                    <li>{t('Interest2')}</li>
                    <li>{t('Interest3')}</li>
                </ul>
            </div>
        </div>
    )
}

export default About;