import 'primeflex/primeflex.css';
import {Routes, Route} from 'react-router-dom';
import "primereact/resources/themes/saga-orange/theme.css"; 
import "primereact/resources/primereact.min.css";                 
import 'primeicons/primeicons.css';
import React, { useState, useEffect, useContext  } from 'react';
import { Menubar } from 'primereact/menubar';
import ExperiencePage from './Pages/Experience/Experience.js';
import AboutPage from './Pages/About/About.js';
import ContactPage from './Pages/Contact/Contact.js';
import ProjectPage from './Pages/Projects/Projects.js';
import MainPage from './Pages/MainPage/MainPage.js';
import './Font.css';
import './i18n.js';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from './LanguageProvider';

function App() {
    
  const { language, changeLanguage } = useContext(LanguageContext);
  const { t, i18n } = useTranslation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      i18n.changeLanguage(language);
      setInitialized(true);
    }
  }, [initialized, language, i18n]);


  const items = [
    {
      label: t('Home'),
      icon:'pi pi-home',
      url: '/'
    },
    {
        label:t('Experience'),
        icon:'pi pi-list',
        url: '/experience'
    },
    {
        label:t('About'),
        icon:'pi pi-fw pi-user',
        url: '/about'
    },
    {
        label:t('Contact'),
        icon:'pi pi-envelope',
        url: '/contact'
    },
    {
      label: t('Projects'),
      icon: 'pi pi-folder',
      url: '/projects'
    },
    {
      label: language === 'fr' ? 'EN' : 'FR',
      icon: 'pi pi-language',
      command: () => changeLanguage(language === 'fr' ? 'en' : 'fr')
    }
  ];

  return (
    <div class="m-5">
      <div>
        <h1 className="font text-center">Justin Alves</h1>
          <div class="pb-4">
              <Menubar model={items} className=""/>
          </div>
      </div>
      <Routes>
        <Route path='/experience' element={<ExperiencePage />}/>
        <Route path='/about' element={<AboutPage/>}/>
        <Route path='/contact' element={<ContactPage/>}/>
        <Route path='/projects' element={<ProjectPage/>}/>
        <Route path="/" element={<MainPage/>}/>
      </Routes>
    </div>
  );
}

export default App;
