import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const LanguageContext = React.createContext();

function LanguageProvider({ children }) {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'FR');
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export default LanguageProvider;
 