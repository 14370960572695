import 'primeflex/primeflex.css';
import "primereact/resources/themes/saga-orange/theme.css"; 
import "primereact/resources/primereact.min.css";                 
import 'primeicons/primeicons.css';
import React, { useState } from 'react';
import '../../Font.css'
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';


function ProjectsPage(){

    const [language, setLanguage] = useState('FR');
    const { t, i18n } = useTranslation();

    const changeLanguage = (lang) => {
        setLanguage(lang);
        i18n.changeLanguage(lang);
    }
    const ccTemplate = (
        <div className="flex align-items-center text-primary">
            <span className="pi pi-car mr-2 text-black-alpha-90"></span>
            <span className="font-bold text-black-alpha-90">Custom Carbons</span>
        </div>
    );


    return (
        <div className="card font fadein animation-duration-1000">
            <div>
                <Fieldset legend={ccTemplate}>
                    <h3>Custom Carbons Final Project</h3>
                    <h4>Description</h4>
                    <p className="m-3">{t('DescriptionText')}</p>
                    <hr></hr>
                    <h4>{t('Goal')}</h4>
                    <p className="m-3">{t('GoalText')}</p>
                    <hr></hr>
                    <h4>{t('TechUsed')}</h4>
                    <p className="m-3">
                    ReactJS, Javascript, HTML, CSS, Bootstrap, Java, Spring, SpringBoot, 
                    PostgreSQL, AWS, AWSCognito, FIGMA, JIRA, GITHUB
                    </p>
                    <hr></hr>
                    <h4>{t('Resp')}</h4>
                    <p className="m-3">{t('RespText')}</p>
                </Fieldset>
            </div>
        </div>
    )
}

export default ProjectsPage;