import 'primeflex/primeflex.css';
import "primereact/resources/themes/saga-orange/theme.css"; 
import "primereact/resources/primereact.min.css";                 
import 'primeicons/primeicons.css';
import '../../Font.css';
import emailjs from "@emailjs/browser";
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import React, { useState, useRef } from 'react';
import { InputTextarea } from "primereact/inputtextarea";
import './Contact.css'
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';

function Contact(){

    const toast = useRef(null);
    const form = useRef();

    const [fName, setFname] = useState('');
    const [lName, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [textArea, setTextArea] = useState('');


    const sendEmail = (e) => {
  e.preventDefault();
  emailjs
    .sendForm(
      "service_to21qkk",
      "template_mblyfgm",
      form.current,
      "LFs-6170qQSzpOly5"
    )
    .then(() => {
      toast.current.show({ severity: 'success', summary: 'Message Sent', life: 3000 });
      setFname('');
      setLname('');
      setEmail('');
      setTextArea('');
    });
}
    const [language, setLanguage] = useState('FR');
    const { t, i18n } = useTranslation();


    return (
        <div className="m-auto font">
            <div className="grid">
            <Toast ref={toast} />
                <div className="col">
                    <h1 className="text-center">Contact</h1>
                    <form className="font" ref={form} onSubmit={sendEmail}>
                            <div className="pt-5">
                                <span class="p-float-label">
                                    <InputText id="firstName" style={{width:"100%"}}  required value={fName} name="first_name" onChange={(e) => setFname(e.target.value)} />
                                    <label htmlFor="firstName">{t('FirstName')}</label>                
                                </span>
                            </div>

                            <div className="pt-5">
                                <span class="p-float-label">
                                    <InputText id="lastName" style={{width:"100%"}} required value={lName} name="last_name" onChange={(e) => setLname(e.target.value)}/>
                                    <label htmlFor="lastName">{t('LastName')}</label>
                                </span>
                            </div>

                            <div className="pt-5">
                                <span class="p-float-label">
                                    <InputText id="email" style={{width:"100%"}} required value={email} name="user_email" onChange={(e) => setEmail(e.target.value)}/>
                                    <label htmlFor="email">{t('Email')}</label>
                                </span>
                            </div>

                            <div className="pt-5">
                                <span class="p-float-label">
                                    <InputTextarea id="textArea" value={textArea} required name="message" onChange={(e) => setTextArea(e.target.value)} rows={5} className="w-full" />
                                    <label htmlFor="textArea">{t('Message')}</label>
                                </span>
                            </div>

                            <div className="pt-4 flex justify-content-center">
                                <Button label={t('SubmitButton')} icon="pi pi-check" type="submit" iconPos="left" className="p-button-success m-2"/>
                            </div>
                    </form>
                </div>

                <div className="col text-center">
                    <h1 className="text-center">{t('SocialTitle')}</h1>
                    <a className="pt-4" id="soBtn" href="https://www.linkedin.com/in/justin-alves-0b4a54199/">
                        <Button label="LinkedIn" icon="pi pi-linkedin" iconPos="left"
                        className="transition-colors transition-duration-300 bg-blue-500 hover:bg-blue-800 text-black hover:text-white border-transparent"/>
                    </a>
                    <a className="pt-4" id="soBtn" href="https://www.instagram.com/justin.alves28/">
                        <Button label="Instagram" icon="pi pi-instagram" iconPos="left"
                        className="transition-colors transition-duration-300 bg-blue-500 hover:bg-pink-600 text-black hover:text-white border-transparent"/>
                    </a>
                    <a className="pt-4" id="soBtn" href="https://github.com/C-hunker">
                        <Button label="GitHub" icon="pi pi-github" iconPos="left"
                        className="transition-colors transition-duration-300 bg-blue-500 hover:bg-gray-900 text-black hover:text-white border-transparent"/>
                    </a>
                </div>
            </div>
            
        </div>
    )
}

export default Contact;