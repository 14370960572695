import 'primeflex/primeflex.css';
import "primereact/resources/themes/saga-orange/theme.css"; 
import "primereact/resources/primereact.min.css";                 
import 'primeicons/primeicons.css';
import '../../Font.css'
import { Fieldset } from 'primereact/fieldset';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../LanguageProvider';


function Experience(){

    const { t, i18n } = useTranslation();
    const { language } = useContext(LanguageContext);

    const educationTemplate = (
        <div className="flex align-items-center text-primary">
            <span className="pi pi-book mr-2 text-black-alpha-90"></span>
            <span className="font-bold text-black-alpha-90">{t('EduTemp')}</span>
        </div>
    );

    const experienceTemplate = (
        <div className="flex align-items-center text-primary">
            <span className="pi pi-briefcase mr-2 text-black-alpha-90"></span>
            <span className="font-bold text-black-alpha-90">{t('ExpTemp')}</span>
        </div>
    );
    
    const skillsTemplate = (
        <div className="flex align-items-center text-primary">
            <span className="pi pi-book mr-2 text-black-alpha-90"></span>
            <span className="font-bold text-black-alpha-90">{t('SkiTemp')}</span>
        </div>
    );

    return (
        <div className="card font fadein animation-duration-1000">
            <div>
                <Fieldset legend={educationTemplate}>
                    <h3>Heritage Regional High School</h3>
                    <h4>{t('HghDate')}</h4>
                    <p className="m-3">{t('HghDesc')}</p>
                    <hr></hr>
                    <h3>Champlain College Saint-Lambert</h3>
                    <h4>{t('CgpDate')}</h4>
                    <p className="m-3">{t('CgpDesc')}</p>
                </Fieldset>
            </div>
            <div className="pt-5">
                <Fieldset legend={experienceTemplate}>
                    <h3>Walmart | {t('WalJob')}</h3>
                    <h4>{t('WalDate')}</h4>
                        <ul className="m-3">
                            <li>{t('WalTasks1')}</li>
                            <li>{t('WalTasks2')}</li>
                            <li>{t('WalTasks3')}</li>
                        </ul>
                    <hr></hr>
                    
                    <h3>Les Entreprises Mobile | {t('MobJob')}</h3>
                    <h4>{t('MobDate')}</h4>
                        <ul className="m-3">
                            <li>{t('MobTasks1')}</li>
                            <li>{t('MobTasks2')}</li>
                            <li>{t('MobTasks3')}</li>
                        </ul>
                </Fieldset>
            </div>
            <div className="pt-5">
                <Fieldset legend={skillsTemplate}>
                
                <div class="grid font">
                    <div class="col">
                    <h4 className="text-center">{t('SkiTitle1')}</h4>
                        <ul>
                            <li>Java</li>
                            <li>C#</li>
                            <li>HTML</li>
                            <li>CSS</li>
                            <li>Bootstrap</li>
                            <li>SQL</li>
                            <li>Python</li>
                            <li>Computer Hardware</li>
                        </ul>
                    </div>
                    <div class="col">
                    <h4 className="text-center">{t('SkiTitle2')}</h4>
                        <ul>
                            <li>Linux Scripting</li>
                            <li>JavaScript</li>
                            <li>React</li>
                            <li>PHP</li>
                            <li>Android Studio</li>
                            <li>Unity Engine</li>
                            <li>ASP.NET</li>
                            <li>Azure</li>
                            <li>JSP</li>
                        </ul>
                    </div>
                    <div class="col">
                    <h4 className="text-center">{t('SkiTitle3')}</h4>
                        <ul>
                            <li>{t('SkiGen1')}</li>
                            <li>{t('SkiGen2')}</li>
                            <li>{t('SkiGen3')}</li>
                            <li>{t('SkiGen4')}</li>
                        </ul>
                    </div>
                </div>
                </Fieldset>
            </div>
        </div>
    )
}

export default Experience;