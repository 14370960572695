import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom"
import App from './App.js';
import './i18n.js'
import LanguageProvider from './LanguageProvider';

ReactDOM.render(
    <LanguageProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </LanguageProvider>,
    document.getElementById('root')
    );
