import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      en: {
        translation: {
         Home: "Home",
         Experience: "Experience",
         About: "About",
         Contact: "Contact",
         Projects: "Projects",
         JobTitle: "Full Stack Developer",
         MainDescription: "Hello! My name is Justin Alves and I am a full stack developer residing in the Montreal area. This is my portfolio website where you can learn various things about me and contact me if needed.",
         CvButton: "Download My CV",
         EduTemp: "Education",
         ExpTemp: "Experience",
         SkiTemp: "Skills",
         HghDate: "August 2014 - June 2019",
         HghDesc: "Excellent results in mathematics and science. Completed secondary IV with top marks in math.",
         CgpDate: "August 2019 - May 2023",
         CgpDesc: "Currently studying for a DEC in Computer Science Technology. Have basic experience with web services and using Docker. Have general knowledge of Java, C#, HTML, Python and many other technologies.",
         WalJob: "Meat Associate",
         WalDate: "April 2019 - June 2020",
         WalTasks1: "Customer Service",
         WalTasks2: "Stocked various food products and rotated them to ensure maximum freshness",
         WalTasks3: "Learned valuable team skills",
         MobJob: "Employee",
         MobDate: "June 2020 - Present",
         MobTasks1: "Tractor Operator",
         MobTasks2: "Team member and team leader",
         MobTasks3: "Shoveler on occasion",
         SkiTitle1: "Proficient",
         SkiTitle2: "Experience",
         SkiTitle3: "General",
         SkiGen1: "Strong work ethic: punctual and dependable",
         SkiGen2: "Quick learner, particularly in hands-on tasks",
         SkiGen3: "Team Player",
         SkiGen4: "Bilingual (English and French)",
         AboutBack: "Background",
         NameText: "Name",
         Biography: "Biography",
         BioText: "For the majority of my life I have had an interest in problem solving and working with computers. I enrolled at Champlain in the computer science program and have been enjoying it ever since. In my free time, I spend it with my friends and family and I ride my motorcycle.",
         BornText: "Born",
         BornDate: "April 2002",
         CurrentEmploymentText: "Current Employment",
         CurrentEmployment: "Software Developer Intern",
         Hobby: "Hobbies",
         Hobby1: "Gaming",
         Hobby2: "Motorcycles",
         Hobby3: "Hockey",
         Interest: "Interests",
         Interest1: "Computer Hardware",
         Interest2: "Software Development",
         Interest3: "Circuit Building",
         FirstName: "First Name",
         LastName: "Last Name",
         Email: "Email",
         Message: "Message",
         SubmitButton: "Submit",
         ResetButton: "Reset",
         SocialTitle: "Social Links",
         DescriptionText: "A project in where a storefront is being used to post custom made carbon parts for cars and motorcycles. Customers are also able to fill in a form to ask for specific part of their liking. Worked in a team of 3 members.",
         Goal: "Goal",
         GoalText: "Allow the owner to publish parts to a storefront that would allow customers to purchase.",
         TechUsed: "Technologies Used",
         Resp: "Responsibilites",
         RespText: "Design diagrams for each use case that I am involved in. Develop each use case from end to end including the tests. Assists teammates with their work and participate in Scrum meetings.",
         TrembAlt: "My group of friends",
         TrembTitle: "Mont Tremblant",
         MotorAlt: "Riding my motorcycle",
         MotorTitle: "Motorcycle Legend",
         FPAlt: "Final Project classmates",
         FPTitle: "Sprint 2 Presentation Day",
         ChristAlt: "Christmas",
         ChristTitle: "Me fr (for real)",
         WalmartAlt: "Prime Walmart",
         WalmartTitle: "Dairy Legend",
         YouppiAlt: "Youppi and my friends",
         YouppiTitle: "Seattle @ Montreal (Jan/09/2023)"
         
        }
      },
      fr: {
        translation: {
            Home: "Accueil",
            Experience: "Expérience",
            About: "À Propos",
            Contact: "Contact",
            Projects: "Projets",
            JobTitle: "Développeur Full Stack",
            MainDescription: "Bonjour! Je m'appelle Justin Alves et je suis un développeur full-stack résidant dans la région de Montréal. Ceci est mon site web de portfolio où vous pouvez en apprendre davantage sur moi et me contacter au besoin.",
            CvButton: "Téléchargez mon CV",
            EduTemp: "Éducation",
            ExpTemp: "Expérience",
            SkiTemp: "Compétences",
            HghDate: "Août 2014 - Juin 2019",
            HghDesc: "Résultats excellents en mathématiques et en sciences. J'ai terminé le secondaire IV avec les meilleures notes en mathématiques.",
            CgpDate: "Août 2019 - Mai 2023",
            CgpDesc: "Actuellement en train d'étudier pour un DEC en Technologie de l'informatique. J'ai une expérience de base avec les services Web et l'utilisation de Docker. J'ai une connaissance générale de Java, C#, HTML, Python et de nombreuses autres technologies.",
            WalJob: "Associé Viande",
            WalDate: "Avril 2019 - Juin 2020",
            WalTasks1: "Service Client",
            WalTasks2: "Stocké divers produits alimentaires et les ai tournés pour assurer une fraîcheur maximale.",
            WalTasks3: "Acquis des compétences précieuses en travail d'équipe.",
            MobJob: "Employé",
            MobDate: "Juin 2020 - Présent",
            MobTasks1: "Opérateur de tracteur",
            MobTasks2: "Membre d'équipe et chef d'équipe.",
            MobTasks3: "Pelleter occasionnellement",
            SkiTitle1: "Compétent",
            SkiTitle2: "Expérience",
            SkiTitle3: "Général",
            SkiGen1: "Fort éthique de travail: ponctuel et fiable.",
            SkiGen2: "Apprentissage rapide, en particulier dans les tâches pratiques.",
            SkiGen3: "Joueur d'équipe",
            SkiGen4: "Bilingue (anglais et français)",
            AboutBack: "À Propos",
            NameText: "Nom",
            Biography: "Biographie",
            BioText: "Depuis la majeure partie de ma vie, j'ai eu un intérêt pour la résolution de problèmes et le travail avec des ordinateurs. J'ai été inscrit au programme d'informatique de Champlain et j'ai aimé cela depuis. Dans mon temps libre, je le passe avec mes amis et ma famille et je fais de la moto.",
            BornText: "Né",
            BornDate: "Avril 2002",
            CurrentEmploymentText: "Emploi Actuel",
            CurrentEmployment: "Stagiaire développeur Full Stack",
            Hobby: "Passe-temps",
            Hobby1: "Jeux vidéo",
            Hobby2: "Les Motos",
            Hobby3: "Hockey",
            Interest: "Intérêts",
            Interest1: "Hardware informatique",
            Interest2: "Développement de logiciels",
            Interest3: "Construction de circuits",
            FirstName: "Prénom",
            LastName: "Nom",
            Email: "Courriel",
            Message: "Laissez-moi un message!",
            SubmitButton: "Soumettre",
            ResetButton: "Réinitialiser",
            SocialTitle: "Réseaux Sociaux",
            DescriptionText: "Un projet dans lequel une vitrine est utilisée pour afficher des pièces de carbone sur mesure pour les voitures et les motos. Les clients sont également en mesure de remplir un formulaire pour demander une partie spécifique de leur goût. Travaillé dans une équipe de 3 membres.",
            Goal: "But",
            GoalText: "Permettre au propriétaire de publier des pièces dans une vitrine qui permettrait aux clients d’acheter.",
            TechUsed: "Technologies Utilisées",
            Resp: "Responsabilités",
            RespText: "Concevoir des diagrammes pour chaque cas d’utilisation auquel je participe. Développer chaque cas d’utilisation de bout en bout, y compris les tests. Aider ses coéquipiers dans leur travail et participer aux réunions de Scrum.",
            TrembAlt: "Mon groupe d'amis",
            TrembTitle: "Mont Tremblant",         
            MotorAlt: "Conduire ma moto",
            MotorTitle: "Légende de la moto",
            FPAlt: "Final Project camarades de classe",
            FPTitle: "Sprint 2 journée de présentation",
            ChristAlt: "Noël",
            ChristTitle: "Moi pdv (pour de vrai)",
            WalmartAlt: "Prime Walmart",
            WalmartTitle: "Légende Associé Produits Laitiers",
            YouppiAlt: "Youppi et mes amis",
            YouppiTitle: "Seattle @ Montreal (Jan/09/2023)"    
        }
      },
    }
  });

export default i18n;