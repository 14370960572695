import gang from '../../Images/Gang.jpg';
import motorcycle from '../../Images/Motorcycle.jpg';
import project from '../../Images/Project.jpg'
import mefr from '../../Images/MeFr.jpg'
import gangthumb from '../../Images/thumbnails/gang-thumb.jpg';
import motorcyclethumb from '../../Images/thumbnails/motorcycle-thumb.jpg';
import projectthumb from '../../Images/thumbnails/sprint-thumb.jpg';
import mefrthumb from '../../Images/thumbnails/fr-thumb.jpg';
import Walmart from '../../Images/Walmart.jpg';
import walmartthumb from '../../Images/thumbnails/walmartthumb.jpg';
import youppi from '../../Images/Youppi.jpg';
import youppithumb from '../../Images/thumbnails/youppi-thumb.jpg'
import { useTranslation } from 'react-i18next';



const PhotoService = {

    
    getData() {
        return [
            {
                itemImageSrc: gang,
                thumbnailImageSrc: gangthumb,
                alt: 'TrembAlt',
                title: 'TrembTitle'
            },
            {
                itemImageSrc: motorcycle,
                thumbnailImageSrc: motorcyclethumb,
                alt: 'MotorAlt',
                title: 'MotorTitle'
            },
            {
                itemImageSrc: project,
                thumbnailImageSrc: projectthumb,
                alt: 'FPAlt',
                title: 'FPTitle'
            },
            {
                itemImageSrc: mefr,
                thumbnailImageSrc: mefrthumb,
                alt: 'ChristAlt',
                title: 'ChristTitle'
            },
            {
                itemImageSrc: Walmart,
                thumbnailImageSrc: walmartthumb,
                alt: 'WalmartAlt',
                title: 'WalmartTitle'
            },
            {
                itemImageSrc: youppi,
                thumbnailImageSrc: youppithumb,
                alt: 'YouppiAlt',
                title: 'YouppiTitle'
            }

        ];
    },

    getImages() {
        return Promise.resolve(this.getData());
    }
}
export default PhotoService;

