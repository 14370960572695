import 'primeflex/primeflex.css';
import "primereact/resources/themes/saga-orange/theme.css"; 
import "primereact/resources/primereact.min.css";                 
import 'primeicons/primeicons.css';
import { Image } from 'primereact/image';
import './MainPage.css';
import portrait from '../../Images/portrait.jpg';
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import resume from './JustinAlvesResume.pdf';

function MainPage(){

    const { t, i18n } = useTranslation();

    return (
        <div class="grid align-items-center">
            <div class="col font text-center">
                <h1>Justin Alves</h1>
                <h2>{t('JobTitle')}</h2>
                <p>{t('MainDescription')}
                </p>
                <a className="pt-4" id="soBtn" href={resume} download="JustinAlvesCV.pdf" target="_blank">
                        <Button label={t('CvButton')} icon="pi pi-download" iconPos="left"
                        className="transition-colors transition-duration-300 bg-blue-500 hover:bg-gray-900 text-black hover:text-white border-transparent"/>
                </a>
            </div>

            <div class="col font justify-content-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Image src={portrait} alt="Image"/>
            </div>
    </div>
    )
}

export default MainPage;